<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-container class="pa-0 my-3">
            <v-row no-gutters>
                <template v-if="model.registerType==='mobile'">
                    <v-col cols="6">
                        <v-select
                            slot="prepend"
                            v-model="model.country"
                            :items="countries"
                            item-text="text"
                            item-value="value"
                            :label="$t('views.passport.register.country')"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="model.mobilePhone"
                            :rules="[rules.required]"
                            :validate-on-blur="false"
                            :error="hasError('mobilePhone')"
                            :error-messages="getError('mobilePhone')"
                            :label="$t('views.passport.register.mobilePhone')"
                            :counter="phoneNumberLength"
                            name="mobilePhone"
                            @change="resetError('mobilePhone')"
                        />
                    </v-col>
                </template>
                <template v-if="model.registerType==='email'">
                    <v-col cols="12">
                        <v-text-field
                            v-model="model.email"
                            :rules="[rules.required, rules.email]"
                            :validate-on-blur="false"
                            :error="hasError('email')"
                            :error-messages="getError('email')"
                            :label="$t('views.passport.register.email')"
                            name="email"
                            @change="resetError('email')"
                        />
                    </v-col>
                </template>
                <v-col cols="12">
                    <v-text-field
                        v-model="model.captcha"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="hasError('captcha')"
                        :error-messages="getError('captcha')"
                        :label="$t('views.passport.register.captcha')"
                        :counter="passportViewModel.captchaOptions.length"
                        :disabled="captchaInputDisabled"
                        name="captcha"
                        autocomplete="off"
                        @change="resetError('captcha')"
                    >
                        <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendRegisterSmsCaptcha">
                            {{ countDownSeconds > 0 ? `(${countDownSeconds}s)` : $t('views.passport.register.sendCaptcha') }}
                        </v-btn>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-btn type="submit" color="primary" :loading="isSubmitting" :disabled="submitButtonDisabled" block x-large>
            {{ $t('views.passport.register.next') }}
        </v-btn>

        <div class="mt-5">
            {{ $t('views.passport.register.agree') }}
            <br />
            <router-link to="/tos">{{ $t('common.tos') }}</router-link>
            &
            <router-link to="/policy">{{ $t('common.policy') }}</router-link>
        </div>
    </v-form>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import countries from '@/models/countries';
import utils from '@/utils';
import { sendRegisterSmsCaptcha, sendRegisterEmailCaptcha, registerPreCheck } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        // 通行证的配置
        passportViewModel: Object
    },
    data () {
        return {
            isLoading: false,
            isSubmitting: false,

            // form
            isFormValid: false,

            model: {
                registerType: '',
                country: 'none',
                mobilePhone: '',
                email: '',
                captcha: '',
                captchaId: null
            },

            // 验证码倒计时
            countDownSeconds: 0,

            // input rules
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required'),
                email: (value) => (value && utils.validate.isEmail(value)) || this.$t('validation.invalidEmailFormat')
            }
        };
    },
    computed: {
        countries () {
            return _(countries)
                .map((c) => {
                    return {
                        value: c.value,
                        text: this.$t(`models.countries.${c.value}`) + ` (+${c.callingCode})`,
                        phoneNumberLength: c.phoneNumberLength
                    };
                })
                .value();
        },
        phoneNumberLength () {
            return _.find(countries, (c) => c.value === this.model.country).phoneNumberLength;
        },
        captchaInputDisabled () {
            var isValidModel = this.model.registerType === 'mobile'
                ? (this.model.mobilePhone && this.model.mobilePhone.length === this.phoneNumberLength)
                : this.model.registerType === 'email'
                    ? this.model.email && utils.validate.isEmail(this.model.email)
                    : false;
            return !isValidModel;
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            var isValidModel = this.model.registerType === 'mobile'
                ? (this.model.mobilePhone && this.model.mobilePhone.length === this.phoneNumberLength)
                : this.model.registerType === 'email'
                    ? this.model.email && utils.validate.isEmail(this.model.email)
                    : false;
            var isValidCaptcha =
                this.model.captcha &&
                this.model.captcha.length === this.passportViewModel.captchaOptions.length &&
                this.model.captchaId;
            return this.isSubmitting || !(isValidModel && isValidCaptcha);
        }
    },
    methods: {
        async sendRegisterSmsCaptcha () {
            try {
                this.model.captchaId = (this.model.registerType === 'mobile')
                    ? await sendRegisterSmsCaptcha({ country: this.model.country, mobilePhone: this.model.mobilePhone })
                    : await sendRegisterEmailCaptcha({ email: this.model.email });

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            try {
                var secret = await registerPreCheck(this.model);
                this.$emit(
                    'submitted',
                    {
                        secret,
                        registerType: this.model.registerType,
                        country: this.model.country,
                        mobilePhone: this.model.mobilePhone,
                        email: this.model.email
                    });
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        }
    },
    mounted () {
        this.model.registerType = this.passportViewModel.passportOptions.registerType;
        this.model.country = this.passportViewModel.smsOptions.defaultCountry;

        // 尝试引用第三方社交账号的名称
        if (this.passportViewModel.provision && this.passportViewModel.provision.name) {
            this.model.name = this.passportViewModel.provision.name;
        }
    }
};
</script>
