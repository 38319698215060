<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-alert type="info" border="left" colored-border elevation="1">
            <div v-if="preCheckResult.registerType==='mobile'">
                {{ $t('views.passport.register.mobileLoginAccountIs') }}
                <b>{{ mobilePhoneAccount }}</b>
            </div>
            <div v-else-if="preCheckResult.registerType==='email'">
                {{ $t('views.passport.register.emailLoginAccountIs') }}
                <b>{{ emailAccount }}</b>
            </div>
        </v-alert>
        <v-card outlined>
            <v-card-title>{{ $t('common.required') }}</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="model.name"
                    :rules="[rules.required]"
                    :validate-on-blur="false"
                    :error="hasError('name')"
                    :error-messages="getError('name')"
                    :label="$t('views.passport.register.name')"
                    :counter="20"
                    :messages="getHint('name')"
                    name="name"
                    @change="resetError('name')"
                />
                <v-text-field
                    v-model="model.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required]"
                    :type="showPassword ? 'text' : 'password'"
                    :error="hasError('password')"
                    :error-messages="getError('password')"
                    :label="$t('views.passport.register.password')"
                    :messages="getHint('password')"
                    name="password"
                    @change="resetError('password')"
                    @click:append="showPassword = !showPassword"
                />
            </v-card-text>
        </v-card>
        <v-card v-if="passportViewModel.showOptionalRegister" outlined class="mt-2">
            <v-card-title>{{ $t('common.optional') }}</v-card-title>
            <v-card-text>
                <v-text-field
                    v-model="model.nameIdentifier"
                    :rules="[]"
                    :validate-on-blur="false"
                    :error="hasError('nameIdentifier')"
                    :error-messages="getError('nameIdentifier')"
                    :label="$t('views.passport.register.nameIdentifier')"
                    :counter="20"
                    :messages="getHint('nameIdentifier')"
                    name="nameIdentifier"
                    @change="resetError('nameIdentifier')"
                />
            </v-card-text>
        </v-card>
        <v-btn type="submit" color="primary" class="my-4" block x-large
            :loading="isSubmitting"
            :disabled="submitButtonDisabled"
        >
            {{ $t('views.passport.register.button') }}
        </v-btn>

    </v-form>
</template>

<script>
import _ from 'lodash';
import countries from '@/models/countries';
import FormMixins from '@/mixins/FormMixins';
import { register } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        // 通行证的配置
        passportViewModel: Object,

        preCheckResult: Object,

        returnUrl: String
    },
    data () {
        return {
            isSubmitting: false,

            // form
            isFormValid: true,

            model: {
                name: '',
                password: '',
                nameIdentifier: ''
            },

            // show password field
            showPassword: false,

            // input rules
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            },

            hint: {
                name: this.$t('views.passport.register.nameHint'),
                password: this.$t('views.passport.register.passwordHint'),
                nameIdentifier: this.$t('views.passport.register.nameIdentifierHint')
            }
        };
    },
    computed: {
        submitButtonDisabled () {
            var modelIsValid =
                this.model.name &&
                this.model.password;
            return !modelIsValid;
        },
        mobilePhoneAccount () {
            var cr = this.preCheckResult;

            var mobilePhone = cr.mobilePhone;
            var country = cr.country;
            if (country === this.passportViewModel.smsOptions.defaultCountry) {
                return mobilePhone;
            } else {
                var prefix = this.passportViewModel.smsOptions.foreignCountryPrefix;
                var callingCode = _.find(countries, (c) => c.value === country).callingCode;
                return `${prefix}${callingCode}${mobilePhone}`;
            }
        },
        emailAccount () {
            return this.preCheckResult.email;
        }
    },
    methods: {
        getHint (field) {
            var error = this.getError(field);
            if (error) {
                return error;
            } else {
                return this.hint[field];
            }
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            try {
                var postData = _.merge({}, this.model, { secret: this.preCheckResult.secret, returnUrl: this.returnUrl });
                var { redirect } = await register(postData);
                // 跳转到指定地址, 因为redirect地址不一定是本站，所以需要用window.location.href
                // 而不能使用 this.$router.push(redirect); 因为$router.push不会访问到服务器
                window.location.href = redirect;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        }
    }
};
</script>
